import { useState, useReducer } from "react";

const initialInputState = {
  value: "",
  isTouched: false,
};

const textareaStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return { value: action.value, isTouched: state.isTouched };
  }
  if (action.type === "BLUR") {
    return { value: state.value, isTouched: true };
  }
  if (action.type === "RESET") {
    return { value: "", isTouched: false };
  }
  if (action.type === "SET") {
    return { value: action.value, isTouched: true };
  }
  return initialInputState;
};

const useTextarea = (validateValue, validateChar) => {
  const [inputState, dispatch] = useReducer(
    textareaStateReducer,
    initialInputState
  );

  const valueIsValid = validateValue(inputState.value);
  const hasError = !valueIsValid && inputState.isTouched;

  const valueChangeHandler = (event) => {
    // console.log(event.nativeEvent.data);
    if (validateChar(event.nativeEvent.data, event.target.value)) {
      dispatch({ type: "INPUT", value: event.target.value });
    }
  };

  const inputBlurHandler = (event) => {
    dispatch({ type: "BLUR" });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  const storeValue = (value) => {
    dispatch({ type: "SET", value: value });
  };

  return {
    value: inputState.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    reset,
    storeValue,
  };
};

export default useTextarea;
